import {
  Box,
  ButtonVariant,
  ButtonSize,
  ColorPreset,
  Glyph,
  Icon,
  Link,
  Space,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { LinkBuilder, Route } from "src/components/routing";
import { Trans } from "@lingui/macro";

import Card from "../Card";
import { Partner } from "../types";
import { MerchantOnboardingSetupEvents } from "../../common/constants/MerchantOnboardingSetupEvents";
import { useSegmentForSetup } from "../../common/hooks/useSegmentForSetup";

interface ReturnToPartnerProps {
  partner?: Partner;
}

const ReturnToPartnerOrDashboard: React.FC<ReturnToPartnerProps> = ({
  partner,
}) => {
  const { sendEvent } = useSegmentForSetup();
  const partnerName = partner?.name ?? "Dashboard";

  return (
    <Card
      header={{
        content: (
          <Text preset={TypePreset.Heading_03}>
            <Trans id="setup.account-status.status-cards.return-to-partner-or-dashboard-header">
              Return to {partnerName}
            </Trans>
          </Text>
        ),
        icon: (
          <Icon
            name={Glyph.Home}
            color={ColorPreset.IconOnLight_02}
            size="24px"
          />
        ),
      }}
      body={
        <Box>
          <Text preset={TypePreset.Body_02} color={ColorPreset.TextOnLight_02}>
            <Trans id="setup.account-status.status-cards.return-to-partner-or-dashboard-body">
              Once we&apos;ve verified your account you&apos;ll be able to start
              collecting payments from your customers.
            </Trans>
          </Text>
          <Space v={2} />
          <LinkBuilder
            absolute={true}
            route={Route.Home}
            onClick={() =>
              sendEvent(
                MerchantOnboardingSetupEvents.SuccessScreenReturnToDashboardCtaClicked
              )
            }
          >
            {({ href }) => (
              <Link
                size={ButtonSize.Lg}
                variant={ButtonVariant.SecondaryOnLight}
                href={partner?.url ?? href}
              >
                <Trans id="setup.account-status.status-cards.return-to-partner-action">
                  Return to {partnerName}
                </Trans>
              </Link>
            )}
          </LinkBuilder>
        </Box>
      }
    />
  );
};

export default ReturnToPartnerOrDashboard;
