import {
  Box,
  BoxProps,
  Color,
  FlexItem,
  FlexItemProps,
} from "@gocardless/flux-react";

export interface ProgressBarProps
  extends BoxProps,
    Omit<FlexItemProps, "children"> {
  progressPercentage: number;
  primaryColor: Color;
  secondaryColor: Color;
  gapSize?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  progressPercentage,
  primaryColor,
  secondaryColor,
  gapSize = "5px",
  ...rest
}) => {
  const actualProgressPercentage = `calc(${progressPercentage * 100}% - 5px)`;
  return (
    <Box {...rest} layout="flex">
      <span
        css={{
          flexBasis: actualProgressPercentage,
          transition: "flex-basis 0.4s ease-out",
        }}
      >
        <Box bg={primaryColor} height="100%" />
      </span>
      <FlexItem basis={gapSize}>
        <Box bg={Color.Dawn_400} height="100%" />
      </FlexItem>
      <FlexItem grow={1}>
        <Box bg={secondaryColor} height="100%" />
      </FlexItem>
    </Box>
  );
};

export default ProgressBar;
