import {
  Box,
  Color,
  ColorPreset,
  Glyph,
  Icon,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import Card from "../Card";

const VerificationInProgress = () => (
  <Card
    header={{
      content: (
        <Text preset={TypePreset.Heading_03}>
          <Trans id="setup.account-status.status-cards.verification-in-progress-header">
            We&apos;re verifying your account
          </Trans>
        </Text>
      ),
      bg: Color.Info_50,
      icon: <Icon name={Glyph.InfoCircle} color={Color.Info_300} size="32px" />,
    }}
    body={
      <Box>
        <Text preset={TypePreset.Body_02} color={ColorPreset.TextOnLight_02}>
          <Trans id="setup.account-status.status-cards.verification-in-progress-body">
            Our verification team are currently verifying your account this can
            take up to 3 working days.
            <br /> <br />
            We will email you once your account is verified.
          </Trans>
        </Text>
      </Box>
    }
  />
);

export default VerificationInProgress;
