import {
  RequiredActionsName,
  RequiredActionsStatus,
} from "@gocardless/api/dashboard/types";

import {
  VERIFICATION_IN_REVIEW_STATES,
  VERIFICATION_PENDING_STATES,
} from "../constants/VerificationStates";
import { RequiredDocument } from "../required-documents/useRequiredDocuments";

export const getPending = (
  requiredDocuments: RequiredDocument[]
): RequiredDocument[] =>
  filterByStatus(requiredDocuments, RequiredActionsStatus.Pending);

export const getInReview = (
  requiredDocuments: RequiredDocument[]
): RequiredDocument[] =>
  filterByStatus(requiredDocuments, RequiredActionsStatus.InReview);

export const getSuccessful = (
  requiredDocuments: RequiredDocument[]
): RequiredDocument[] =>
  filterByStatus(requiredDocuments, RequiredActionsStatus.Successful);

const filterByStatus = (
  requiredDocuments: RequiredDocument[],
  statusToFilter: RequiredActionsStatus
): RequiredDocument[] => {
  let filteredDocuments: RequiredDocument[] = [];

  switch (statusToFilter) {
    case RequiredActionsStatus.InReview:
      filteredDocuments = requiredDocuments.filter(
        ({ name, status }) =>
          VERIFICATION_IN_REVIEW_STATES.includes(status) ||
          // send_verification_deposit behaves differently: it will be pending even though it's in_review, so we want
          // to include it here:
          (name === RequiredActionsName.SendVerificationDeposit &&
            status === RequiredActionsStatus.Pending)
      );
      break;
    case RequiredActionsStatus.Pending:
      filteredDocuments = requiredDocuments.filter(
        ({ name, status }) =>
          VERIFICATION_PENDING_STATES.includes(status) &&
          // send_verification_deposit behaves differently: it will be pending even though it's actually in_review, so
          // we exclude it here to avoid duplicating it in the status list:
          name !== RequiredActionsName.SendVerificationDeposit
      );
      break;
    default:
      filteredDocuments = requiredDocuments.filter(
        ({ status }) => status === statusToFilter
      );
      break;
  }

  return filteredDocuments;
};
