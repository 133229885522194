import { Trans } from "@lingui/macro";
import { Text } from "@gocardless/flux-react";

import { StatusCard } from "./StatusCard";

export const Failed: React.FC<{
  primaryAdminEmail?: string;
}> = ({ primaryAdminEmail: paEmail }) => {
  const primaryAdminEmail = <Text className="fs-mask">{paEmail}</Text>;

  return (
    <StatusCard
      icon="AccountCouldNotBeVerified"
      title={<Trans>Sorry, we could not verify your account</Trans>}
      description={
        <Trans>
          Unfortunately we were unable to verify your account. Our verification
          team has reached out to {primaryAdminEmail} regarding this decision.
        </Trans>
      }
      body={<></>}
    />
  );
};
