import {
  Box,
  Color,
  ColorPreset,
  useTheme,
  XYGrid,
} from "@gocardless/flux-react";

interface CardProps {
  header: {
    content: JSX.Element;
    bg?: Color;
    icon: JSX.Element;
  };
  body: JSX.Element;
}

const VerificationCard: React.FC<CardProps> = (props) => {
  const { theme } = useTheme();
  const { header, body } = props;
  const { bg, icon } = header;

  return (
    <Box
      borderColor={ColorPreset.BorderOnLight_04}
      borderWidth={1}
      borderRadius={1}
      bg={ColorPreset.BackgroundLight_01}
    >
      <Box
        gutterH={2}
        gutterV={2}
        bg={bg}
        css={{
          borderRadius: "16px 16px 0px 0px",
        }}
      >
        <XYGrid templateColumns="max-content auto" columnGap={1}>
          {icon}
          {header.content}
        </XYGrid>
      </Box>
      <Box
        gutterH={2}
        gutterV={2}
        css={{
          borderTop: `1px solid ${theme.color(ColorPreset.BorderOnLight_04)}`,
        }}
      >
        {body}
      </Box>
    </Box>
  );
};

export default VerificationCard;
