import { Box, Color, FontWeight, Text } from "@gocardless/flux-react";
import { Plural } from "@lingui/macro";

import { useActivationDiscount } from "./useActivationDiscount";

const ActivationDiscountCountdown = (): JSX.Element | null => {
  const { remainingDays } = useActivationDiscount();

  if (!remainingDays) return null;

  return (
    <Box
      borderWidth={2}
      borderColor={Color.Dawn_700}
      borderRadius={1}
      gutterH={0.75}
      gutterV={0.75}
      spaceBelow={1}
    >
      <Text weight={FontWeight.Bold}>
        <Plural
          id="setup.activation-discount.days-left"
          value={remainingDays}
          one="# day left with no transaction fees"
          other="# days left with no transaction fees"
        />
      </Text>
    </Box>
  );
};

export default ActivationDiscountCountdown;
