import {
  AlignItems,
  Box,
  ColorPreset,
  H3,
  JustifyContent,
  P,
  TextAlign,
  TypePreset,
} from "@gocardless/flux-react";
import { Image, ImageName } from "src/components/images";

interface StatusCardProps {
  icon: ImageName;
  title: JSX.Element;
  description: JSX.Element;
  body: JSX.Element;
}

export const StatusCard: React.FC<StatusCardProps> = ({
  icon,
  title,
  description,
  body,
}) => (
  <Box
    borderColor={ColorPreset.BorderOnLight_04}
    borderWidth={1}
    borderRadius={1}
    gutterH={[1.5, 4]}
    gutterV={[2, 4]}
    bg={ColorPreset.BackgroundLight_01}
  >
    <Box
      layout="flex"
      alignItems={AlignItems.Center}
      justifyContent={JustifyContent.Center}
      spaceBelow={2}
    >
      <Image name={icon} width={["72px", "96px"]} />
    </Box>

    <H3
      textAlign={TextAlign.Center}
      preset={TypePreset.Heading_06}
      color={ColorPreset.TextOnLight_01}
      spaceBelow={1}
    >
      {title}
    </H3>

    <P
      textAlign={TextAlign.Center}
      preset={TypePreset.Body_02}
      color={ColorPreset.TextOnLight_02}
      spaceBelow={3}
    >
      {description}
    </P>

    <Box maxWidth={380} style={{ margin: "auto" }}>
      {body}
    </Box>
  </Box>
);
