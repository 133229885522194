import { CreditorsVerificationStatus } from "@gocardless/api/dashboard/types";
import { Box } from "@gocardless/flux-react";
import { useCallback, useEffect, useMemo } from "react";
import { CountryCodes } from "src/common/country";
import { NextSteps } from "src/components/routes/Setup/optimised-verification-status/next-steps/NextSteps";
import { usePrimaryCreditor } from "src/queries/creditor";
import { useOrganisation } from "src/queries/organisation";

import { Partner } from "../account-status/types";
import { useSegmentForSetup } from "../common/hooks/useSegmentForSetup";
import { MerchantOnboardingSetupEvents } from "../common/constants/MerchantOnboardingSetupEvents";
import { useCollectionsPermitted } from "../../SetupPayments/common/hooks/useCollectionsPermitted";

import { DocumentsToUpload } from "./required-actions/DocumentsToUpload";
import { useRequiredDocuments } from "./required-documents/useRequiredDocuments";
import { ActionRequired } from "./status-cards/ActionRequired";
import { Failed } from "./status-cards/Failed";
import { InReview } from "./status-cards/InReview";
import { Successful } from "./status-cards/Successful";
import { getPending } from "./helpers/requiredDocumentsFilter";

export const OptimisedVerificationStatus: React.FC<{
  status: CreditorsVerificationStatus;
  partner?: Partner;
}> = ({ status, partner }) => {
  const {
    data: requiredDocuments,
    hasUnsuccessful,
    mutate,
    loading,
  } = useRequiredDocuments();

  const creditor = usePrimaryCreditor();
  const organisation = useOrganisation();
  const { sendEvent } = useSegmentForSetup();
  const { collectionsEnabledTrackingAttribute } = useCollectionsPermitted();

  const updateRequiredDocuments = useCallback(async () => {
    await mutate();

    // Get refreshed list of required documents after 60 seconds
    setTimeout(() => {
      mutate();
    }, 60000);
  }, [mutate]);

  useEffect(() => {
    if (loading) return;

    const pendingDocuments = getPending(requiredDocuments);
    const requiredDocumentNames = pendingDocuments.map(
      (requiredDocument) => requiredDocument.name
    );
    const uniqueRequiredDocumentNames = [...new Set(requiredDocumentNames)];
    const requiredDocumentsCount = pendingDocuments.length;

    sendEvent(MerchantOnboardingSetupEvents.SuccessScreenPageViewed, {
      verification_status: status,
      new_design: true,
      pending_document_types: uniqueRequiredDocumentNames,
      pending_documents_count: requiredDocumentsCount,
      ...collectionsEnabledTrackingAttribute,
    });
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, loading]);

  const body = useMemo(() => {
    if (
      !creditor ||
      !creditor.id ||
      !creditor.geo ||
      !creditor.creditor_type ||
      !organisation ||
      !organisation.primary_admin_email
    )
      return null;

    const creditorId = creditor.id;
    const creditorGeo = creditor.geo as CountryCodes;
    const creditorType = creditor.creditor_type;
    const primaryAdminEmail = organisation.primary_admin_email;

    if (hasUnsuccessful)
      return <Failed primaryAdminEmail={primaryAdminEmail} />;

    switch (status) {
      case CreditorsVerificationStatus.ActionRequired:
        return (
          <>
            <ActionRequired
              creditorGeo={creditorGeo}
              creditorType={creditorType}
              requiredDocuments={requiredDocuments}
            />
            <DocumentsToUpload
              creditorId={creditorId}
              creditorGeo={creditorGeo}
              creditorType={creditorType}
              requiredDocuments={requiredDocuments}
              updateRequiredDocuments={updateRequiredDocuments}
              notificationEmail={primaryAdminEmail}
            />
          </>
        );
      case CreditorsVerificationStatus.InReview:
        return (
          <>
            <InReview
              creditorGeo={creditorGeo}
              creditorType={creditorType}
              requiredDocuments={requiredDocuments}
            />
            <DocumentsToUpload
              creditorId={creditorId}
              creditorGeo={creditorGeo}
              creditorType={creditorType}
              requiredDocuments={requiredDocuments}
              updateRequiredDocuments={updateRequiredDocuments}
              notificationEmail={primaryAdminEmail}
            />
            <NextSteps
              notificationEmail={primaryAdminEmail}
              partner={partner}
            />
          </>
        );
      case CreditorsVerificationStatus.Successful:
        return <Successful partner={partner} />;
    }
  }, [
    creditor,
    organisation,
    hasUnsuccessful,
    status,
    requiredDocuments,
    updateRequiredDocuments,
    partner,
  ]);

  return (
    <Box
      maxWidth={700}
      className="fs-unmask"
      id="optimised-verification-status"
    >
      {body}
    </Box>
  );
};
