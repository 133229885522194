import {
  Box,
  ButtonVariant,
  ButtonSize,
  Color,
  ColorPreset,
  Glyph,
  Icon,
  Space,
  Text,
  TypePreset,
  Button,
} from "@gocardless/flux-react";
import { Route } from "src/components/routing";
import { Trans } from "@lingui/macro";
import ActivationDiscountCountdown from "src/components/routes/Setup/common/components/ActivationDiscount/ActivationDiscountCountdown";
import { useSegmentForSetup } from "src/components/routes/Setup/common/hooks/useSegmentForSetup";
import { TrackingEvent } from "src/common/trackingEvents";
import { routerPush } from "src/common/routing";

import Card from "../Card";

const AccountActionRequired = (): JSX.Element => {
  const { sendEventPromise } = useSegmentForSetup();

  return (
    <Card
      header={{
        content: (
          <Text preset={TypePreset.Heading_03}>
            <Trans id="setup.account-status.status-cards.action-required">
              Action required: we need some additional information to verify
              your account
            </Trans>
          </Text>
        ),
        bg: Color.Warning_50,
        icon: (
          <Icon
            name={Glyph.ExclamationCircle}
            color={Color.Warning_300}
            size="32px"
          />
        ),
      }}
      body={
        <Box>
          <ActivationDiscountCountdown />
          <Text preset={TypePreset.Body_02} color={ColorPreset.TextOnLight_02}>
            <Trans id="setup.account-status.status-cards.more-details-needed">
              We need some more details to complete your account verification.
              Click the button below to continue.
            </Trans>
          </Text>
          <Space v={2} />

          <Button
            role="link"
            variant={ButtonVariant.PrimaryOnLight}
            size={ButtonSize.Lg}
            onClick={async () => {
              await sendEventPromise(
                TrackingEvent.VERIFICATION_ADD_ADDITIONAL_INFORMATION_CLICKED
              );

              routerPush({
                route: Route.Onboarding,
                routeParams: { step: "verification-status" },
                queryParams: { setup: "true" },
              });
            }}
          >
            <Trans id="setup.account-status.status-cards.add-additional-information">
              Add additional information
            </Trans>
          </Button>
        </Box>
      }
    />
  );
};

export default AccountActionRequired;
