import { CreditorType } from "@gocardless/api/dashboard/types";
import { Box } from "@gocardless/flux-react";
import { CountryCodes } from "src/common/country";

import { getUploadConfig } from "../config";
import { RequiredDocument } from "../required-documents/useRequiredDocuments";
import { getInReview, getPending } from "../helpers/requiredDocumentsFilter";

import { RequiredActionCard } from "./RequiredActionCard";

export const DocumentsToUpload: React.FC<{
  creditorId: string;
  creditorGeo: CountryCodes;
  creditorType: CreditorType;
  requiredDocuments: RequiredDocument[];
  updateRequiredDocuments: () => Promise<void>;
  notificationEmail: string;
}> = ({
  creditorId,
  creditorGeo,
  creditorType,
  requiredDocuments,
  updateRequiredDocuments,
  notificationEmail,
}) => {
  const pending = getPending(requiredDocuments);
  const inReview = getInReview(requiredDocuments);

  const items = [...pending, ...inReview].map((document) => {
    const {
      status: actionStatus,
      entity,
      name,
      verificationId,
      bankAccountNumberEnding,
    } = document;
    const { actionComponent, acceptedDocuments, documentRequirements } =
      getUploadConfig(name, creditorGeo);

    if (!actionComponent) return null;

    return (
      <Box key={verificationId} spaceAbove={[1.5, 3]}>
        <RequiredActionCard
          creditorId={creditorId}
          creditorGeo={creditorGeo}
          creditorType={creditorType}
          verificationId={verificationId}
          bankAccountNumberEnding={bankAccountNumberEnding}
          entity={entity}
          actionComponent={actionComponent}
          actionStatus={actionStatus}
          acceptedDocuments={acceptedDocuments}
          documentRequirements={documentRequirements}
          updateRequiredDocuments={updateRequiredDocuments}
          notificationEmail={notificationEmail}
        />
      </Box>
    );
  });

  return <Box>{items}</Box>;
};
