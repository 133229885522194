import { Trans } from "@lingui/macro";
import { RequiredActionsStatus } from "@gocardless/api/dashboard/types";

import { StatusItem } from "./StatusItem";

export const DetailsSubmitted = () => (
  <StatusItem
    title={<Trans>Thanks for submitting your details</Trans>}
    status={RequiredActionsStatus.Successful}
  />
);
