import { useEffect, useState } from "react";
import { useOrganisationPreferences } from "src/queries/organisationPreferences";
import { useActivationDiscount } from "src/components/routes/Setup/common/components/ActivationDiscount/useActivationDiscount";
import { DiscountType } from "@gocardless/api/dashboard/types";

import {
  RewardFeeDiscountDestination,
  RewardFeeDiscountDialog,
} from "./RewardFeeDiscountDialog";

export const RewardFeeDiscountDialogSetupVariant = () => {
  const { organisation_preferences, updateOrganisationPreferences } =
    useOrganisationPreferences();
  const { activationDiscount } = useActivationDiscount();

  const [openModal, setOpenModal] = useState(false);

  const onRewardDialogContinueClick = () => {
    setOpenModal(false);
    updateOrganisationPreferences({
      preferences: {
        reward_collections_enabled_dialog_viewed: true,
      },
    });
  };

  useEffect(() => {
    if (
      (activationDiscount?.discount_type ===
        DiscountType.The15DaysFreeFeeActivationCompleteSetup ||
        activationDiscount?.discount_type ===
          DiscountType.The30DaysFreeFeeActivationCompleteSetup) &&
      !organisation_preferences?.reward_collections_enabled_dialog_viewed
    ) {
      setOpenModal(true);
    }
  }, [activationDiscount, organisation_preferences]);

  return (
    <RewardFeeDiscountDialog
      destination={RewardFeeDiscountDestination.VERIFICATION_STATUS}
      openModal={openModal}
      onContinueClick={onRewardDialogContinueClick}
    />
  );
};
