import { CreditorType } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { CountryCodes } from "src/common/country";

import { RequiredDocument } from "../required-documents/useRequiredDocuments";

import { StatusCard } from "./StatusCard";
import { StatusList } from "./StatusList";

export const InReview: React.FC<{
  creditorGeo: CountryCodes;
  creditorType: CreditorType;
  requiredDocuments: RequiredDocument[];
}> = ({ creditorGeo, creditorType, requiredDocuments }) => (
  <StatusCard
    icon="AccountVerifyingTick"
    title={<Trans>We&apos;re verifying your account</Trans>}
    description={
      <Trans>
        Once your account is verified, you can add customers and collect
        payments through GoCardless.
      </Trans>
    }
    body={
      <StatusList
        creditorGeo={creditorGeo}
        creditorType={creditorType}
        requiredDocuments={requiredDocuments}
      />
    }
  />
);
