import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { CreditorsVerificationStatus as VerificationStatusEnum } from "@gocardless/api/dashboard/types";
import { Box, Interpose, Space } from "@gocardless/flux-react";

import PageTitle from "../common/components/PageTitle";

import AccountActionRequired from "./status-cards/AccountActionRequired";
import AccountVerified from "./status-cards/AccountVerified";
import { VerificationStatusProps } from "./VerificationStatus";
import VerificationInProgress from "./status-cards/VerificationInProgress";
import PartnerEtvNextSteps from "./PartnerEtvNextSteps";

const PartnerEtvVerificationStatus: React.FC<VerificationStatusProps> = ({
  status,
  partner,
}) => {
  const pageTitle =
    status === VerificationStatusEnum.InReview ? (
      <PageTitle
        title={i18n._(
          t({
            id: "setup.account-status.thanks",
            message: "Thanks for submitting your details",
          })
        )}
      />
    ) : (
      <PageTitle
        title={i18n._(
          t({ id: "account-status.page-title", message: "Your account" })
        )}
      />
    );

  const verificationCard = (verificationStatus: VerificationStatusEnum) => {
    switch (verificationStatus) {
      case VerificationStatusEnum.ActionRequired:
        return <AccountActionRequired />;
      case VerificationStatusEnum.InReview:
        return <VerificationInProgress />;
      case VerificationStatusEnum.Successful:
        return <AccountVerified partner={partner} />;
    }
  };

  return (
    <Box>
      {pageTitle}
      <Interpose node={<Space v={3} />}>
        {verificationCard(status)}
        <PartnerEtvNextSteps />
      </Interpose>
    </Box>
  );
};

export default PartnerEtvVerificationStatus;
