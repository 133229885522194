import {
  Box,
  ButtonVariant,
  ButtonSize,
  ColorPreset,
  Glyph,
  Icon,
  Space,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { Route } from "src/components/routing";
import { Link } from "src/components/routing/Link.new";
import { Trans } from "@lingui/macro";

import Card from "../Card";
import { MerchantOnboardingSetupEvents } from "../../common/constants/MerchantOnboardingSetupEvents";
import { useSegmentForSetup } from "../../common/hooks/useSegmentForSetup";

const BrandingAndCustomisation = (): JSX.Element => {
  const { sendEvent } = useSegmentForSetup();
  return (
    <Card
      header={{
        content: (
          <Text preset={TypePreset.Heading_03}>
            <Trans id="setup.account-status.status-cards.branding-and-customisation-header">
              Branding and customisation
            </Trans>
          </Text>
        ),
        icon: (
          <Icon
            name={Glyph.Edit}
            color={ColorPreset.IconOnLight_02}
            size="24px"
          />
        ),
      }}
      body={
        <Box>
          <Text preset={TypePreset.Body_02} color={ColorPreset.TextOnLight_02}>
            <Trans id="setup.account-status.status-cards.branding-and-customisation-body">
              Customise what your customers will see on payment pages, email
              notifications and their bank statements..
            </Trans>
          </Text>
          <Space v={2} />
          <Link
            route={Route.Brand}
            size={ButtonSize.Lg}
            variant={ButtonVariant.SecondaryOnLight}
            onClick={() =>
              sendEvent(
                MerchantOnboardingSetupEvents.SuccessScreenEditBrandCtaClicked
              )
            }
          >
            <Trans id="setup.account-status.status-cards.edit-your-branding">
              Edit your branding
            </Trans>
          </Link>
        </Box>
      }
    />
  );
};

export default BrandingAndCustomisation;
