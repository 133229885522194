import {
  AppAuthorisationResource,
  RequiredActionResource,
  RequiredActionsName,
  RequiredVerificationResource,
} from "@gocardless/api/dashboard/types";

import { Statuses } from ".";

/**
 * Does a merchants account have one connected partner app?
 *
 * If so return the url and name so it can be presented
 *
 */
export const getPartnerApp = (
  appAuthorisations?: AppAuthorisationResource[]
): { url: string | undefined; name: string | undefined } | undefined => {
  const hasOneApp = appAuthorisations?.length === 1;
  if (!hasOneApp) return;
  const [app] = appAuthorisations;
  return {
    url: app?.post_onboarding_url || app?.website_url,
    name: app?.name,
  };
};

/**
 * Does a merchant have any verifications 'pending'
 * their action?
 */
export const hasPendingVerifications = (
  requiredVerifications: RequiredVerificationResource[]
): boolean =>
  !!requiredVerifications.find((i) =>
    i.status ? Statuses.Pending.includes(i.status) : false
  );

/**
 * Does a merchant have any verifications that are
 * in review by GoCardless?
 *
 * This will apply to all charity creditor types, which
 * always require a manual check by GC
 */
export const hasInReviewVerifications = (
  requiredVerifications: RequiredVerificationResource[]
): boolean =>
  !!requiredVerifications.find((i) =>
    i.status ? Statuses.InReview.includes(i.status) : false
  );

/**
 * Does a merchant have any pending actions?
 *
 * If the required action is SendVerificationDeposit
 * we need to check that deposit confirmed is additionally
 * set to false.
 */
export const hasPendingActions = (
  requiredActions: RequiredActionResource[]
): boolean =>
  requiredActions.some((i) =>
    i.status
      ? i.name === RequiredActionsName.SendVerificationDeposit
        ? Statuses.Pending.includes(i.status) &&
          i.entity?.deposit_confirmed === false
        : Statuses.Pending.includes(i.status)
      : false
  );

/**
 * If a merchants required actions include a succesful record
 * regarding the bank account check and there are no pending
 * or inReview verifications we can consider the account
 * succesfully verified
 */
export const isSuccessful = (
  requiredVerifications: RequiredVerificationResource[],
  requiredActions: RequiredActionResource[]
): boolean =>
  requiredActions.every((i) =>
    i.status ? Statuses.Successful.includes(i.status) : false
  ) &&
  !hasPendingVerifications(requiredVerifications) &&
  !hasInReviewVerifications(requiredVerifications);

/**
 * If a merchants required actions include an unsuccessful
 * we can assume the merchant has failed verification
 */
export const isUnsuccessful = (
  requiredActions: RequiredActionResource[]
): boolean =>
  requiredActions.some((i) =>
    i.status ? Statuses.Unsuccessful.includes(i.status) : false
  );
