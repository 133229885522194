import { useState } from "react";
// @todo all this partner ETV stuff needs isolating
import {
  Accordion,
  AccordionItem,
  AccordionMode,
  AccordionSize,
  AccordionVariant,
  AlignItems,
  Box,
  Button,
  ButtonVariant,
  FontWeight,
  Glyph,
  Icon,
  JustifyContent,
  P,
  Space,
  Text,
  useTheme,
  Link,
  ColorPreset,
  AccordionOutline,
} from "@gocardless/flux-react";
import { Route } from "src/components/routing";
import { routerPush } from "src/common/routing";
import { MerchantOnboardingSetupEvents } from "src/components/routes/Setup/common/constants/MerchantOnboardingSetupEvents";
import { Trans } from "@lingui/macro";

import { useSegmentForSetup } from "../common/hooks/useSegmentForSetup";

import { useXeroOpenUrl } from "./useXeroOpenUrl";

interface LeftAccessoryProps {
  name: Glyph;
}

const LeftAccessory: React.FC<LeftAccessoryProps> = ({ name }) => (
  <Box
    layout="flex"
    flexDirection="row"
    justifyContent={JustifyContent.Center}
    alignItems={AlignItems.Center}
    width="32px"
    height="32px"
    bg={ColorPreset.BackgroundLight_03}
    css={{
      borderRadius: "16px",
    }}
  >
    <Icon name={name} color={ColorPreset.IconOnLight_01} size="16px" />
  </Box>
);

export default function PartnerEtvNextSteps() {
  const { theme } = useTheme();
  const { sendEvent } = useSegmentForSetup();
  const xeroOpenUrl = useXeroOpenUrl();

  const [initialSelectedId, setInitialSelectedId] = useState("nextSteps1");

  function handleAccordionChange([item]: string[]) {
    let section;
    if (item === "nextSteps1") {
      section = "1";
    } else if (item === "nextSteps2") {
      section = "2";
    } else if (item === "nextSteps3") {
      section = "3";
    } else {
      return;
    }

    sendEvent(MerchantOnboardingSetupEvents.VerificationNextStepsExpanded, {
      partner_name: "Xero",
      section,
    });
  }

  return (
    <Box key={initialSelectedId}>
      <Text size={8} weight={FontWeight.SemiBold}>
        Next steps
      </Text>
      <Space v={1.5} />
      <Accordion
        mode={AccordionMode.Single}
        initialSelected={[initialSelectedId]}
        size={AccordionSize.Md}
        variant={AccordionVariant.SolidOnLight}
        outline={AccordionOutline.Solid}
        css={{
          "--accordion-bg": theme.color(ColorPreset.BackgroundLight_01),
        }}
        onChange={handleAccordionChange}
      >
        <AccordionItem
          id="nextSteps1"
          title="Visit Xero settings"
          leftAccessory={() => <LeftAccessory name={Glyph.Settings} />}
        >
          <P>
            Complete a quick setup in your Xero settings so you&apos;ll be ready
            to start using GoCardless.
          </P>
          <Space v={1} />
          <Link
            href={xeroOpenUrl}
            target="_blank"
            variant={ButtonVariant.PrimaryOnLight}
            onClick={() => {
              sendEvent(
                MerchantOnboardingSetupEvents.VerificationNextStepsCtaClicked,
                { partner_name: "Xero", target: "complete_setup" }
              );
              setInitialSelectedId("nextSteps2");
            }}
          >
            Complete setup
          </Link>
          <Space v={0.5} />
        </AccordionItem>
        <AccordionItem
          id="nextSteps3"
          title="Brand your customer experience"
          leftAccessory={() => <LeftAccessory name={Glyph.Edit} />}
        >
          <P>
            Tailor what your customers will see on payment pages, email
            notifications and their bank statements.
          </P>
          <Space v={1} />
          <Button
            variant={ButtonVariant.PrimaryOnLight}
            onClick={() => {
              sendEvent(
                MerchantOnboardingSetupEvents.VerificationNextStepsCtaClicked,
                { partner_name: "Xero", target: "edit_your_branding" }
              );
              routerPush({ route: Route.Brand });
            }}
          >
            <Trans id="setup.account-status.status-cards.edit-your-branding">
              Edit your branding
            </Trans>
          </Button>
          <Space v={0.5} />
        </AccordionItem>
      </Accordion>
    </Box>
  );
}
