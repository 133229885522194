import { useAppAuthorisationList } from "@gocardless/api/dashboard/app-authorisation";
import {
  AppAuthorisationResource,
  CreditorsVerificationStatus,
} from "@gocardless/api/dashboard/types";
import { Route } from "src/common/routing";
import { useVerificationStatus } from "src/common/hooks/useVerificationStatus";

import { UseSetupPage } from "../routing/types";

import { Partner } from "./types";
import { getPartnerApp } from "./helpers";

export interface UseAccountStatus extends UseSetupPage {
  appAuthorisations?: AppAuthorisationResource[];
  partner?: Partner;
  status: CreditorsVerificationStatus;
}

export const useAccountStatus = (): UseAccountStatus => {
  const appAuthorisations = useAppAuthorisationList();
  const apps = appAuthorisations?.data?.app_authorisations;

  const { loaded: verificationStatusLoaded, status } = useVerificationStatus();

  return {
    loaded: verificationStatusLoaded && !!appAuthorisations,
    partner: getPartnerApp(apps),
    status,
    appAuthorisations: apps,
    route: Route.AccountStatus,
    completed: false, // last page in the setup flow, we want to always fallback to this.
  };
};
