import { useRequiredActionList } from "@gocardless/api/dashboard/required-action";
import {
  RequiredActionsDetails,
  RequiredActionsName,
  RequiredActionsStatus,
} from "@gocardless/api/dashboard/types";
import { useMemo } from "react";

export interface RequiredDocument {
  verificationId: string;
  name: RequiredActionsName;
  status: RequiredActionsStatus;
  entity: Record<string, string>;
  details?: RequiredActionsDetails;
  bankAccountNumberEnding?: string;
  lastTransitionTime?: string;
}

export const useRequiredDocuments = () => {
  const { data: requiredActions, isLoading, mutate } = useRequiredActionList();

  const requiredDocuments = useMemo(() => {
    const result: RequiredDocument[] = [];
    requiredActions?.required_actions?.forEach(
      ({
        id: verificationId,
        name,
        status,
        entity,
        details,
        account_number_ending: bankAccountNumberEnding,
        last_transition_time: lastTransitionTime,
      }) => {
        if (verificationId && name && status) {
          result.push({
            verificationId,
            name,
            status,
            entity: entity || {}, // no nulls are allowed
            details,
            bankAccountNumberEnding,
            lastTransitionTime: lastTransitionTime || undefined,
          });
        }
      }
    );
    return result;
  }, [requiredActions?.required_actions]);

  return {
    data: requiredDocuments,
    hasUnsuccessful: !!requiredDocuments.find(
      ({ status }) => status === RequiredActionsStatus.Unsuccessful
    ),
    loading: isLoading,
    mutate: mutate,
  };
};
