import { isProd } from "src/common/hosts";

enum XeroEnv {
  Live = "Live",
  UAT = "UAT",
  Test = "Test",
}

const xeroEnvToOpenUrl: Record<XeroEnv, string> = {
  [XeroEnv.Live]:
    "https://go.xero.com/app/payment-services?setuppayments=1&type=complete-gocardless",
  [XeroEnv.UAT]:
    "https://go.livestage6.test.xero.com/app/payment-services?setuppayments=1&type=complete-gocardless",
  [XeroEnv.Test]:
    "https://go.fringe3.xero-test.com/app/payment-services?setuppayments=1&type=complete-gocardless",
};

export function useXeroOpenUrl(): string {
  const xeroEnv = isProd ? XeroEnv.Live : XeroEnv.UAT;

  return xeroEnvToOpenUrl[xeroEnv];
}
